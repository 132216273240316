import React from "react";
import FooterLogo from "../../assets/logoFooter.inline.svg";
import { useStaticQuery, graphql } from "gatsby";
import FooterMenu from "./FooterMenu";
import Img from "gatsby-image";
import parse from "html-react-parser";
import "../../sass/layout/_footer.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allWp {
        edges {
          node {
            id
            myOptionsPage {
              acf_options {
                youtube
                vimeo
                vatRegNumber
                twitter
                phoneFormatted
                phone
                mapLongitude
                mapLatitude
                mapLogo {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                linkedin
                icoRegNumber
                fieldGroupName
                email
                directionUrl
                cookiePopupText
                companyRegNumber
                address
                accreditationLogos {
                  logoLink
                  footerLogo {
                    altText
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
                mapImage {
                  altText
                  localFile {
                    childImageSharp {
                      fixed(height: 800, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <footer className="footer footer-relative">
        <div className="wrapper">
          <div className="footer-top">
            <FooterLogo />
            <div className="brand_logos">
              <ul className="logos">
                {data.allWp.edges[0].node.myOptionsPage.acf_options.accreditationLogos.map((logo, index) => {
                  return (
                    <li className="logo-img" key={"logo_footer_" + index}>
                      <a href={logo.logoLink} target="_blank" rel="noreferrer">
                        {!logo.footerLogo.localFile.childImageSharp && logo.footerLogo.localFile.extension === "svg" && <img src={logo.footerLogo.localFile.publicURL} alt={logo.footerLogo.altText} width="50" height="50" />}
                        {logo.footerLogo.localFile.extension === "png" && <img src={logo.footerLogo.localFile.publicURL} alt={logo.footerLogo.altText} width="50" height="50" />}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="footer-middle">
            <div className="footer-middle--left">
              <div className="contact-section contact_wrap">
                <h3 className="label">Registered Address</h3>
                <div className="footer-wrap">
                  <div className="address">
                    <a href={data.allWp.edges[0].node.myOptionsPage.acf_options.directionUrl} target="_blank" rel="noreferrer">
                      {parse(data.allWp.edges[0].node.myOptionsPage.acf_options.address)}
                    </a>
                  </div>
                  <div className=" phone_email">
                    <div className="phone">
                      <span className="bold">T: </span> <a href={"tel:" + data.allWp.edges[0].node.myOptionsPage.acf_options.phoneFormatted}>{data.allWp.edges[0].node.myOptionsPage.acf_options.phone}</a>
                    </div>
                    <div className="email">
                      <span className="bold">E: </span> <a href={"mailto:" + data.allWp.edges[0].node.myOptionsPage.acf_options.email}>{data.allWp.edges[0].node.myOptionsPage.acf_options.email}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-section contact_wrap">
                <h3 className="label">Follow Edge45® SEO York Agency</h3>
                <div className="footer-wrap">
                  <div className=" phone_email">
                    <ul className="social">
                      {data.allWp.edges[0].node.myOptionsPage.acf_options.twitter !== null ? (
                        <li key={data.allWp.edges[0].node.myOptionsPage.acf_options.twitter}>
                          <a href={data.allWp.edges[0].node.myOptionsPage.acf_options.twitter} target="_blank" rel="noreferrer">
                            <i className="twitter"></i>
                            <div className="indent_text">twitter</div>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {data.allWp.edges[0].node.myOptionsPage.acf_options.linkedin !== null ? (
                        <li key={data.allWp.edges[0].node.myOptionsPage.acf_options.linkedin}>
                          <a href={data.allWp.edges[0].node.myOptionsPage.acf_options.linkedin} target="_blank" rel="noreferrer">
                            <i className="linkedin"></i>
                            <div className="indent_text">linked in</div>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}

                      {data.allWp.edges[0].node.myOptionsPage.acf_options.vimeo !== null ? (
                        <li key={data.allWp.edges[0].node.myOptionsPage.acf_options.vimeo}>
                          <a href={data.allWp.edges[0].node.myOptionsPage.acf_options.vimeo} target="_blank" rel="noreferrer">
                            <i className="vimeo"></i>
                            <div className="indent_text">vimeo</div>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}

                      {data.allWp.edges[0].node.myOptionsPage.acf_options.youtube !== null ? (
                        <li key={data.allWp.edges[0].node.myOptionsPage.acf_options.youtube}>
                          <a href={data.allWp.edges[0].node.myOptionsPage.acf_options.youtube} target="_blank" rel="noreferrer">
                            <i className="youtube"></i>
                            <div className="indent_text">youtube</div>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      <li key={"instagram_footer"}>
                        <a href={"https://www.instagram.com/edge45/"} target="_blank" rel="noreferrer">
                          <i className="instagram"></i>
                          <div className="indent_text">instagram</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <a className="map-mobile" href={data.allWp.edges[0].node.myOptionsPage.acf_options.directionUrl} target="_blank" rel="noreferrer">
                <div className="map-wrap">
                  <div className="marker-wrap">
                    <div className="pin-wrap">
                      <div className="pin">
                        {data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.localFile?.childImageSharp?.fluid ? "" : "image not found"}
                        <Img fluid={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.localFile?.childImageSharp?.fluid} alt={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.altText} />
                      </div>
                      <div className="shadow"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  {data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.localFile?.childImageSharp?.fluid ? "" : "image not found"}
                  <Img fixed={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapImage?.localFile?.childImageSharp?.fixed} width={10} alt={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.altText} className="map-image" />
                </div>
                <div className="indent_text">Google Maps</div>
              </a>
              <div className="contact-section links_wrap">
                <h3 className="label">Navigation</h3>
                <div className="footer-wrap">
                  <FooterMenu />
                </div>
              </div>
            </div>
            <div className="footer-middle--right">
              <a href={data.allWp.edges[0].node.myOptionsPage.acf_options.directionUrl} target="_blank" rel="noreferrer">
                <div className="map-wrap">
                  <div className="marker-wrap">
                    <div className="pin-wrap">
                      <div className="pin">
                        {data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.localFile?.childImageSharp?.fluid ? "" : "image not found"}
                        <Img fluid={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.localFile?.childImageSharp?.fluid} alt={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.altText} />
                      </div>
                      <div className="shadow"></div>
                      <div className="pulse"></div>
                    </div>
                  </div>
                  {data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.localFile?.childImageSharp?.fluid ? "" : "image not found"}
                  <Img fixed={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapImage?.localFile?.childImageSharp?.fixed} alt={data?.allWp?.edges[0]?.node?.myOptionsPage?.acf_options?.mapLogo?.altText} className="map-image" />
                </div>
                <div className="indent_text">Google Maps</div>
              </a>
            </div>
          </div>

          <div className="footer-bottom">
            Company Registered Number: {data.allWp.edges[0].node.myOptionsPage.acf_options.companyRegNumber} | VAT Registration Number: {data.allWp.edges[0].node.myOptionsPage.acf_options.vatRegNumber} | ICO Registration number: {data.allWp.edges[0].node.myOptionsPage.acf_options.icoRegNumber} | Edge45® {year} All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
